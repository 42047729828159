<template>
    <section class="content">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card card-primary">
                    <!-- /.card-header -->
                    <!-- form start -->
                    <form role="form" @submit.prevent="submitForm">
                        <div class="invoice p-3 mb-3">
                            <!-- title row -->
                            <!-- info row -->
                            <!-- /.row -->
                            <input id="ujian_id" class="form-control" v-model="form.ujian_id" type="hidden" name="ujian_id" />
                            <div class="row">
                                <div class="col-md-12 form-group" v-if="false">
                                    <label class="control-label">Nomor</label>
                                    <input id="nomor_soal" class="form-control" type="number" name="nomor_soal" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="card card-default">
                                    <!-- <form role="form" @submit="submitForm"> -->
                                    <div class="card-header">
                                        <h3 class="card-title"></h3>

                                        <div class="card-tools">
                                            <button
                                            type="button"
                                            class="btn btn-tool"
                                            data-card-widget="collapse"
                                            >
                                            <i class="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <!-- /.card-header -->
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-12">
                                            <label class="control-label">Konten Soal</label>
                                            <vue-editor v-model="form.soal" :editor-toolbar="customToolbar" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-2"></div>
                            <!-- form order items -->
                            <h4 class="mb-2">
                                <i class="fa fa-tags"></i> &nbsp; List Jawaban
                            </h4>
                            <div class="text-rightx">
                                <button type="button" v-on:click="addItem" data-action="create" class="btn btn-primary" title="Buat Baru">
                                <i class="fas fas fa-plus"></i> Tambah Jawaban
                                </button>
                            </div>
                            <table class="table table-hover" id="tbljawaban" ref="tbljawaban">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>NO. JAWABAN</th>
                                        <th>JAWABAN</th>
                                        <th>KUNCI JAWABAN</th>
                                        <th>ACTION</th>
                                    </tr>
                                </thead>
                                <tbody @click="handleClick"></tbody>
                            </table>
                            <div class="row">
                                <div class="card card-default">
                                    <!-- <form role="form" @submit="submitForm"> -->
                                    <div class="card-header">
                                        <h3 class="card-title"></h3>

                                        <div class="card-tools">
                                            <button
                                            type="button"
                                            class="btn btn-tool"
                                            data-card-widget="collapse"
                                            >
                                            <i class="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <!-- /.card-header -->
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-12">
                                            <label class="control-label">Pembahasan</label>
                                            <vue-editor v-model="form.pembahasan" :editor-toolbar="customToolbar" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <button type="submit" class="btn btn-primary mr-1">Simpan</button>
                            <router-link
                            :to="{ path: '/detail-ujian/' + form.ujian_id }"
                            class="btn btn-secondary"
                            >
                            Kembali
                            </router-link>
                        </div>
                        <!-- Tambah Manual -->
                    </form>
                </div>
            </div>
        </div>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit.prevent="saveItem">
                        <div class="modal-header">
                            <h4 class="modal-title"></h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="bg-secondary p-3 mb-3 d-none">
                                <i class="fa fa-info"></i> FORM JAWABAN
                            </div>
                            <div class="row">
                                <input type="hidden" name="id" id="id" v-model="formItem.id" />
                                <div class="col-md-6 form-group" v-if="false">
                                    <label class="control-label">Nomor</label>
                                    <input id="nomor_soal" class="form-control" type="number" name="nomor_soal" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">KONTEN JAWABAN</label>
                                    <vue-editor v-model="formItem.jawaban" :editor-toolbar="customToolbar" />
                                </div>
                                <div class="col-md-6 form-group mt-3">
                                    <label class="control-label">JADIKAN KUNCI JAWABAN?</label>
                                    <div class="icheck-material-orange icheck-inline form-check">
                                        <input class="form-check-input" id="kunci" v-model="formItem.kunci" type="checkbox" name="kunci[]" />
                                        <label class="form-check-label" for="kunci">
                                            Jawaban Benar
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" id="append" @click="submitItemType='append'" class="btn btn-primary">
                                Tambah item
                            </button>
                            <button type="submit" id="update" @click="submitItemType='update'" class="btn btn-default">
                                Update item
                            </button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                Tutup
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Vue from "vue";
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import "vue-select/dist/vue-select.css";
import { VueEditor } from "vue2-editor";
import "icheck-material";

export default {
    data() {
        return {
            errors: [],
            loading: false,
            roles: "",
            show: false,
            method: "POST",
            form: {
                ujian_id: "",
                // nomor_soal: "",
                soal: "<h1>Tulis konten soal di sini..</h1>",
                pembahasan: "<h1>Tulis pembahasan soal di sini..</h1>"
            },
            formItem: {
                id: "",
                nomor_jawaban: "",
                jawaban: "<h1>Tulis konten jawaban di sini..</h1>",
                kunci: "",
            },
            sender: {},
            customToolbar: [
                [{ font: [] }],
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ header: 1 }, { header: 2 }],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ color: [] }, { background: [] }],
                ["link", "image", "video"],
                [{ direction: "rtl" }],
            ],
        };
    },
    components: { 
        VueEditor,
    },
    created: function() {
        // alert(this.$route.params.id);
        if (this.$route.params.id) {
            this.loadSoal(this.$route.params.id);
            this.form.ujian_id = this.$route.params.ujian_id;
            console.log("id ujian: ",this.$route.params.ujian_id);
            // this.shouldDisable = true;
        } else {
            this.form.ujian_id = this.$route.params.ujian_id;
            console.log("id ujian: ",this.$route.params.ujian_id);
        }
        // var now = moment();
        // window.onbeforeunload = this.beforeGo;
        // //get data courier for dropdown select
        // authFetch("/master/courier/groups")
        //     .then((res) => {
        //         if (res.status === 201) {} else if (res.status === 400) {}
        //         return res.json();
        //     })
        //     .then((js) => {
        //         this.totalCourier = js.data;
        //     });
    },
    methods: {
        loadSoal(id) {
            authFetch("/akademik/ujian/soal/" + id).then((res) => {
                res.json().then((json) => {
                    if (json) {
                        this.form = json;
                    } else {
                        Swal.fire("Proses gagal", ``, "error");
                    }
                });
            });
        },
        resetFormItem()
        {
            this.formItem = {
                id: "",
                nomor_jawaban: "",
                jawaban: "<h1>Tulis konten jawaban di sini..</h1>",
                kunci: "",
            };
        },
        getFormItem()
        {
            var aData = {
                id: this.formItem.id,
                nomor_jawaban: this.formItem.nomor_jawaban,
                jawaban: this.formItem.jawaban,
                kunci: this.formItem.kunci,
            };
            return aData;
        },
        getDataItem(aData)
        {
            return {
                id: aData.id,
                nomor_jawaban: aData.nomor_jawaban,
                jawaban: aData.jawaban,
                kunci: aData.kunci == "1" ? true : false,
            };
        },
        addItem: function() {
            const e = this.$refs;
            this.resetFormItem();
            $("#update").hide();
            $("#append").show();
            $(e.formDialog).modal("show");
        },
        submitForm: function(ev) {
            const self = this;
            var data = this.tbl.getSerialize();
            self.form.id = self.$route.params.id;
            var setmethod = self.form.id ? "PUT" : "POST";
            var items = [];
            this.tbl
                .api()
                .rows()
                .every(function(rowIdx, tableLoop, rowLoop) {
                    var d = this.data();
                    items.push(d);
                });

            var allData = { group: self.form, items: items };
            var urlSubmit = "/akademik/ujian/soal";
            if (self.method == "PUT") urlSubmit = "/akademik/ujian/soal/" + self.form.id;

            Swal.fire({
            title: "Simpan?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                authFetch(urlSubmit, {
                    method: setmethod,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(allData),
                })
                    .then((res) => {
                    return res.json();
                    })
                    .then((js) => {
                    if (js.success) {
                        Swal.fire("Proses Berhasil", ``, "success");
                        this.$router.push("/detail-ujian/" + self.form.ujian_id);
                    } else {
                        Swal.fire("Proses gagal", ``, "error");
                        // this.table.api().ajax.reload();
                    }
                    });
                }
            });

            ev.preventDefault();
        },
        handleClick(e) {
            const evt = this.$refs;
            if (e.target.closest("button")) {
                var btnEl = $(e.target).closest("button").get(0);
                if (btnEl.dataset.action == "remove") {
                    var orderId = btnEl.dataset.id;
                    if(!orderId)
                    {
                        var nRow = $(e.target).closest("tr").get(0);
                        this.tbl.fnDeleteRow(nRow);
                    } else {
                        authFetch('/akademik/ujian/jawaban/'+orderId, {
                            method: 'DELETE',
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            },
                            body: 'id='+orderId,
                        })
                        .then((res) => {
                            // this.submitting1 = true;
                            return res.json();
                        })
                        .then((js) => {
                            if(js.success)
                            {
                                var nRow = $(e.target).closest("tr").get(0);
                                this.tbl.fnDeleteRow(nRow);
                            }
                        });
                    }
                } else if (e.target.dataset.action == "edit") {
                    var nRow = $(e.target).closest("tr").get(0);
                    var iRow = this.tbl.fnGetPosition(nRow);
                    var aData = this.tbl.fnGetData(iRow);

                    this.formItem = this.getDataItem(aData);
                    this.formItem.nRow = nRow;
                    $("#append").hide();
                    $("#update").show();
                    $(evt.formDialog).modal("show");
                }
                return false;
            }
        },
        saveItem: function(ev) {
            var btnId = this.submitItemType;
            if(ev.submitter)
                btnId = ev.submitter.id
            ev.preventDefault();
            if (btnId == "append") {
                this.appendItem(ev);
            } else if (btnId == "update") {
                this.updateItem(ev);
            }
        },
        appendItem: function(ev) {
            const e = this.$refs;
            // this.errors = [];
            let nRow = this.tbl.fnAddRow(this.formItem);
            var aData = this.getFormItem();
            this.tbl.fnUpdate(aData, nRow);
            $("td .btn.edit", nRow).click();
            this.resetFormItem();
            $(e.formDialog).modal("hide");
        },
        updateItem: function(ev) {
            const e = this.$refs;
            // this.errors = [];
            let nRow = this.formItem.nRow;
            var aData = this.getFormItem();
            this.tbl.fnUpdate(aData, nRow);
            $("td .btn.edit", nRow).click();
            this.resetFormItem();
            $(e.formDialog).modal("hide");
        },
    },
    mounted() {
        const e = this.$refs;
        const self = this;

        this.tbl = createTable(e.tbljawaban, {
            title: "",
            serverSide: false,
            ajax: "/akademik/ujian/jawaban",
            paramData: function(d) {
                if (self.$route.params.id) {
                    d.ujian_soal_id = self.$route.params.id;
                } else {
                    d.ujian_soal_id = 0;
                }
            },
            selectedRowClass: "",
            frame: false,
            displayLength: 100,
            lengthChange: false,
            filter: false,
            order: [[ 0, "asc" ]],
            columns: [
                { data: "id", visible: false },
                { data: "nomor_jawaban", visible: false },
                { data: "jawaban" },
                { data: "kunci" },
                { data: "action",
                render: function (data, type, row, meta) {
                    return `<div class="btn-group">
        <button type="button" class="btn btn-sm btn-outline-warning" data-action="edit" data-id="` +
                            row.id +
                            `"><i class="fas fa-edit"></i> Edit</button>
                    <button type="button" class="btn btn-sm btn-outline-danger" data-action="remove" data-id="` +
                            row.id +
                            `"><i class="fas fa-trash"></i> Hapus</button>
                </div>`;
                }, },
            ],
            rowCallback: function(row, data) {
                if (data.kunci == "1") {
                    $("td:eq(1)", row).html('<h5 class="text-center"><i class="fa fa-check"></i></h5>');
                } else if(data.kunci == "0") {
                    $("td:eq(1)", row).html('');
                }
            },
        }).fnEditor({
            action: '<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Hapus</a></div>',
        });
    },
}
</script>